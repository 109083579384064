export default [
  {
    id: 1,
    type: 'pdf',
    title: "Stu Buchbinder",
    subtitle: "RESUME",
    backgroundColor: "#e95a44",
    thumbnail: "./images/stu.png"
  },
  {
    id: 2,
    type: 'work',
    title: "InContextSolutions - ShopperMX",
    subtitle: "Reduce time, cost, and risk of developing in-store experiences in VR",
    backgroundColor: "#e95a44",
    thumbnail: "./images/incontext_thumb.png",
    description:
      "<center><img width=\"100%\" src='./images/incontext_1.png' /></center>" +
      "<p>With the ShopperMX platform retailers and brands quickly learn how their in-store merchandising ideas will resonate with shoppers.</p>" +
			"<p>I was a Sr. Unity Engineer. My duties involved programming in Unity, C#, and Visual Studio.</p>" +
			"<p><a href=\"https://www.incontextsolutions.com/\">InContext Solutions</a></p>" 
  },
  {
    id: 3,
    type: 'work',
    title: "Philips Groomify",
    subtitle: "Go beard yourself!",
    backgroundColor: "#e95a44",
    thumbnail: "./images/groomify_thumb.png",
    description:
    "<video width=\"100%\" height=\"100%\" controls><source src=\"./video/groomify.mp4\" type=\"video/mp4\"> Your browser does not support the video tag.</video></center>" +
    "<br /><br />" +
    "A digital grooming solution that uses augmented reality (AR) and facial mapping to enable people to ‘virtually’ try a range of trending beard styles in real time. In addition to the AR-enabled functionality, the service will create personalized advice with AI-enabled facial analysis and will assist men in executing and maintaining facial styles. <br />" +
    "<p>I was the lead engineer on this project. My responsibilities included client interaction, project scoping, risk assesment, and various other pm tasks. My main task was to create a cocoa touch static framework from a Unity generated iOS project.</p>" +
    "<p>I exported all the beards as bundles to be hosted on a server,while unity project contained all the AR logic, bundle loading & unloading logic, and beard blendshape logic." +
    " <p>I programmed a custom plugin to surface Unity messages to the containing IOS application, and vice versa. Then when the unity app loads external bundles, which are the beards, and maps it to a user's face on the front-facing camera.</p>" +
    "<p>The beards contain blendshape scripts to drive the animation when the user opens/closes their mouth. The beards will sync up with the face points on the user's jaw.</p>"
  },
  {
    id: 4,
    type: 'work',
    title: "Footlocker: The Hunt",
    subtitle: "An augmented reality scavenger hunt",
    backgroundColor: "#e95a44",
    thumbnail: "./images/footlocker_thumb.png",
    description:
      "<center><img src='./images/footlocker.png' /></center>" +
      "<p>Foot Locker tasked Firstborn to integrate a new AR functionality into their existing mobile app—allowing them to offer customers exclusive content and experiences timed to limited-release product drops. The app update was released timed to one of the biggest shoe drops of the year—LeBron James’ Signature Nike 16 “KING PURPLE” sneaker launch. We offered LA-based sneakerheads a chance to be one of the first to grab a pair by participating in an AR scavenger hunt. Instead of camping out in line, “The Hunt” let fans use their app to unlock geo-targeted AR clues throughout the city that led them to the coveted limited-edition kicks. The sneakers sold out in less than two hours, while customers enjoyed a unique AR-powered experience.</p><p> My role on the project was to architect the AR portion of the app using Unity and targeting ARKit (iOS). The core of the application is a headless AR experience that loads content via the Footlocker API using asset bundles. Built in Unity, the iOS project was then compiled into a static Cocoa Touch framework that the client could integrate into their existing Footlocker iOS application.</p><p>There’s a short video case study here.</p>" +
      "<center>" +
      "<iframe src=\"https://player.vimeo.com/video/296448105?title=0&byline=0&portrait=0\" width=\"100%\" height=\"360\" frameborder=\"0\" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>" +
      "<p><a href=\"https://vimeo.com/296448105\">Foot Locker: The Hunt</a> from <a href=\"https://vimeo.com/user15232527\">Firstborn</a> on <a href=\"https://vimeo.com\">Vimeo</a>.</p>" +
      "</center>"
  },
  {
    id: 5,
    type: 'work',
    title: "VR Analytics",
    subtitle: "A easurement platform that captures biometrics to record user's emotions in VR",
    backgroundColor: "#e95a44",
    thumbnail: "./images/vranalytics_thumb.png",
    description:
      "<div id=\"project_preview\"><video width=100% controls><source src=\"./video/vranalytics.mp4\" type=\"video/mp4\" /></div>" +
      "<p id=\"quote\">\"Isobar named one of Top Ten Most Innovative Companies in AR/VR\" - Fast Company</p>" +
			"<p>A mechanism to replay behavioral and emotional data captured during VR experiences, the platform enables stakeholders to actually “go into” the VR application and analyze areas that have stimulated an emotional reaction from the user. Multiple stakeholders can simultaneously view users’ avatars playing back inside the VR session as it was experienced. The avatar changes color based on the user's emotions, such as interest, happiness, and disappointment.</p>" +
      "<p>My duties on the project were to lead unity development, implement Networking strategry to enable remote collaboration among the team, work closely with 3D developers to the UI, a </p>" +
      "<p>Watch the <a href=\"https://adage.com/videos/ad-age-next-what-neuroscience-says-about-vr/1415\">video</a></p>"
  },
  {
    id: 6,
    type: 'work',
    title: "Futurecraft 4D",
    subtitle: "Use AR to try on shoes",
    backgroundColor: "#e95a44",
    thumbnail: "./images/futurecraft_thumb.png",
    description:
      "<video width=100% controls><source src=\"./video/adidas.mp4\" type=\"video/mp4\">Your browser does not support the video tag.</video>" +
      "<p>Created in Unity using photogrammetry and Vuforia model tracking, I developed this prototype for Adidas to demonstrate trying on a virtual shoe in augmented reality.</p>"
  },
  {
    id: 7,
    type: 'work',
    title: "Virtual Resorts",
    subtitle: "Streamline the development of new properties with the power of",
    backgroundColor: "#e95a44",
    thumbnail: "./images/wyndham_thumb.png",
    description:
      "<center><img src='./images/virtualresorts_kitchen.png' /></center>" +
      "Created in Unity, this application shows a custom approach to architecture and design of new properties at Wyndham. Virtual Interiors demonstrates how to save costs and cut time on production by modeling units in VR before they are physically built."
  },
  {
    id: 8,
    type: 'work',
    title: "Enterprise",
    subtitle: "Rent-A-Car iOS application written in Objective-C.",
    backgroundColor: "#e95a44",
    thumbnail: "./images/enterprise_thumb.png",
    description:
     "<center><img src='./images/enterprise.png' /></center>" +
     "<p>The Enterprise Rent-A-Car App is your own free personal assistant for all things car rental. You can easily view or modify upcoming reservations, get directions to a rental branch, call roadside assistance, or find a rental car for your next trip at one of our 7,000+ worldwide locations.</p>" +
     "<p>Created in xcode with objective-c and utilizing an MVVM design pattern, my main responsiblity was to create the North America prepay feature. </p>" +
     "<p><a href=\"https://itunes.apple.com/us/app/enterprise-rent-a-car/id1020641417?mt=8\"><img src=\"./images/ios_appstore_badge.png\"></a></p>"
  },
  {
    id: 9,
    type: 'work',
    title: "Virtual Resorts",
    subtitle: "An immersive virtual vacation experience.",
    backgroundColor: "#e95a44",
    thumbnail: "./images/vacationality_thumb.jpg",
    description:
      "<video width=100% controls><source src=\"./video/wyndham_isaacaward2017_01.mp4\" type=\"video/mp4\">Your browser does not support the video tag.</video>" +
      "<p>A roomscale VR experience that allows users to freely navigate and experience 3 different Wyndham hotel experiences: Chicago, Hawaii, and The Smokey Mountains.</p>" +
      "<p>We created this experience using photogrammetry and lidar scanning of the 3 properties. After those results were processed, we imported them into Unity and created interactable environments using the SteamVR plugin." +
      "<p>I came in later on in the project, once all the environments were scanned. My responsibilities mainly revolved around code, render, and shader optimizations. I added occlusion culling to redue the load on the GPU and updated some of the controls for a better user experience."
  },
  {
    id: 10,
    type: 'work',
    title: "Monkey Math School Sunshine",
    subtitle: "A game that teaches pattern recognition, numbers, and arithmetic for children",
    backgroundColor: "#e95a44",
    thumbnail: "./images/monkeymath_thumb.png",
    description:
     "<iframe width=\"100%\" height=\"370\" src=\"https://www.youtube.com/embed/mX6VWaTyDrc\" title=\"Monkey Math School Sunshine \" frameborder=\"0\" allow=\"autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture\" allowfullscreen></iframe>" +
     "<p>I was contracted by Thup Games to port this game from Cocos2D to Unity. </p>" +
     "<p>Monkey Math School Sunshine allows your child to build fundamental math skills while they are endlessly entertained.</p>" +
     "<p>9 engaging games teach your child about" +
     "<ul><li>sequencing</li><li>patterning</li><li>counting</li><li>addition & subtraction</li></ul>" +
     "<br /><p><a href=\"https://apps.apple.com/us/app/monkey-math-school-sunshine/id451287325\"><img src=\"./images/ios_appstore_badge.png\"></a></p>"
  },
  {
    id: 11,
    type: 'work',
    title: "Discovery Techbook",
    subtitle: "A digital curriculum for schools that adhere to the 5 e's of learning",
    backgroundColor: "#e95a44",
    thumbnail: "./images/techbook_thumb.jpg",
    description:
      "<center><img src='./images/techbook1.png' /></center>" +
      "<p>A fully digital service that replaces traditional textbooks and transforms teaching and learning.<p>" +
			"<p>It is built around the 5E model of instruction and a non-linear curriculum pathway that promotes inquiry-based learning, enhances critical thinking, and improves student achievement.</p>" +
			"<p>Discovery Education Techbook&#8482; is the all-in-one, multi-modal resource that will engage and support every type of learner.</p>" +
			"<p><a href=\"https://www.discoveryeducationglobal.com/solutions/science-techbook\"><img src=\"./images/ios_appstore_badge.png\"></a></p>" 
  },
  {
    id: 12,
    type: 'work',
    title: "Redbox",
    subtitle: "rent, stream, and buy on the mobile app",
    backgroundColor: "#e95a44",
    thumbnail: "./images/redbox_thumb.png",
    description:
      "<center><img width=\"100%\" src='./images/redbox_1.png' /></center>" +
      "<ul>" +
      "<li>Rent & pick up new release movies on DVD, Blu-ray and 4K UHD starting at just $1.99 a night at any of the 41,000 kiosk locations</li>" +
			"<li>Buy used movies to own forever. Just choose a favorite, select “Buy at the Box” and pick it up at a Box near you.</li>" +
      "<li>Stream Free On Demand – and choose from hundreds of movies & TV shows to watch free with ads. Also, enjoy a variety of comedy stand-up specials, documentaries and more. No sign in required.</li>"+
      "</ul>"+
			"<p>I was a Sr. iOS Engineer on the mobile team and most of my work was related to the developing'MyRedbox' feature of the app.</p>" +
			"<p><a href=\"https://apps.apple.com/us/app/redbox-rent-stream-buy/id339532909?platform=iphone\"><img src=\"./images/ios_appstore_badge.png\"></a></p>" 
  },

];
