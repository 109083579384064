import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./App.css";

import NavBar from './components/NavBar';
import HomePage from "./components/HomePage";
import ProjectDetails from "./components/ProjectDetails";

function App() {
  return (
    <Router>
      <NavBar />
      <Switch>
        <Route exact path="/" component={HomePage} />
        <Route exact path="/project" component={ProjectDetails} />
      </Switch>
    </Router>
  );
}

export default App;
