import React, {Fragment} from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  root: {
    position: "relative",
    margin: '20px'
  },
  title: {
    color: "#222",
    fontFamily: "Verdana",
    fontWeight: "500",
    fontSize: '18pt',
    letterSpacing: '1px',
  },
  subtitle: {
    color: "##888",
    fontFamily: "Verdana",
    fontWeight: "500",
    fontSize: '10pt',
    fontStyle: 'italic',
    letterSpacing: '1px',
  },
}));

export default function NavBar() {
  const styles = useStyles();
  return (
    <Fragment>
      <div className={styles.root}>
        <div className={styles.title} >Stu Buchbinder</div>
        <div className={styles.subtitle} >Software Engineer / Innovation Architect</div>
       </div>
    </Fragment>
  )
}
