import React, { useState } from "react";
import Fade from "react-reveal/Fade";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";

const useStyles = makeStyles(theme => ({
  root: {
    height: 'auto',
    width: '270px',
    maxWidth: '640px',

  },
  container: {
    position: "relative",
    width: "100%",
    height: "100%"
  },
  banner: {
    ...theme.typography.title,
    position: "absolute",
    margin: "auto",
    top: "70%",
    width: "100%",
    height: "30%",
    // marginBottom: "10%",
    justifyContent: "center",
    alignItems: "center"
  },
  image: {
    objectFit: "cover",
    width: "100%",
    height: "100%"
  },
  title: {
    color: "white",
    marginLeft: '14px',
    fontFamily: "sans-serif",
    fontSize: '12pt',
  },
  subtitle: {
    color: "white",
    marginLeft: '14px',
    marginTop: '-12px',
    fontFamily: "sans-serif",
    fontSize: '10pt',
  },
}));

function ProjectSquare({ project }) {
  const classes = useStyles();

  const [isShown, setIsShown] = useState(false);

  return (
    <div
      className={classes.root}
      onMouseEnter={() => setIsShown(true)}
      onMouseLeave={() => setIsShown(false)}
    >
      <Link to={`/project?id=${project.id}`}>
        <div className={classes.container}>
          <img
            className={classes.image}
            src={project.thumbnail}
            alt={project.thumbnail}
          />
          {isShown && (
            <Fade  center>
              <div
                className={classes.banner}
                style={{
                  backgroundColor: project.backgroundColor
                }} >
                  <h3 className={classes.title}>{project.title}</h3>
                  <div className={classes.subtitle}>{project.subtitle}</div>
              </div>
            </Fade>
          )}
        </div>
      </Link>
    </div>
  );
}

export default ProjectSquare;
