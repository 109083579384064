import React, { useState, useEffect } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import allProjects from "../utils/projects";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";

const useStyles = makeStyles(() => ({
    root: {
        margin: '20px'
    },
    projectHeader: {
        backgroundColor: "#000"
    },
    title:{
        fontFamily: "Menlo, sans-serif",
        fontSize: "32px",
        fontWeight: "100",
        textAlign: "left",
        color: "#FFF",
        padding: "24px 30px 17px 30px",
        letterSpacing: "-2px"
    },
    description: {
        marginTop: "0px",
        fontFamily: "Menlo, sans-serif",
        fontSize: "12px",
        fontWeight: "100",
        lineHeight: "170%",
        color: "#000",
    },
    backBtn: {
        color: '#FFF',
        width: '100%',
        height: '100%',
    },
    image: {
        height: '100%'
    }
}));

const gotoResume = () => {

    window.open('./resume.pdf', '_blank');
    window.location.href = "/";
}
 const ProjectDetails = (props) => {
    const classes = useStyles();

    const [project, setProject] = useState({});
   
    useEffect(() => {
      
        let search = props.location.search;
        let params = new URLSearchParams(search);
        let id = params.get('id');
        // eslint-disable-next-line eqeqeq
        let projectInfo = allProjects.find(p => p.id == id);
        setProject(projectInfo);

     }, [props.location.search])

  
    return (
      
        <Grid container spacing={12}>
            
            <Grid item xs />
            <Grid item xs className={classes.root}>
                <div className={classes.projectHeader}>
                    <Link to={`/`}>
                        <Button>
                            <h5 className={classes.backBtn}>back</h5>
                        </Button>
                    </Link>
                    <div className={classes.title}>
                        {project.title}
                    </div>
                </div>
  
                {
                   project.type === "pdf" ? 
                    (  
                        <div>
                            {
                                gotoResume()
                            }
                         
                        </div>
                    ) :
                    (  
                        <div 
                            className={classes.description}
                            dangerouslySetInnerHTML= {{ __html: project.description}} />
                    )
                    
                }
              
            </Grid>
            <Grid item xs />
        </Grid>
        
    )
}

export default ProjectDetails;
