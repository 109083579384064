import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ProjectSquare from "./ProjectSquare";
import projectsData from "../utils/projects";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles(theme => ({
  root: {
    position: "relative",
    margin: "20px 20px auto 20px",
  }
}));

function HomePage() {
  const styles = useStyles();

  const projects = projectsData.map(project => {
    return <ProjectSquare key={project.id} project={project} />;
  });
  return (
      <div className={styles.root}>
        <Grid container spacing={12}>
            {projects}
        </Grid>
      </div>
  );
}

export default HomePage;
